import * as bootstrap from 'bootstrap';

import './application.scss';
import jquery from "jquery";


window.$ = window.jQuery = jquery;

let check_strategy = function() {
    let type = $('#types input:checked').val();
    $('#strategy .tab').hide();
    $(`#strategy .${type}`).show();

    $('#payments').hide();
    $(`#payments.${type}`).show();
}

let check_payment = function() {
    let type_p = $('#payments input:radio:checked').val();
    console.log(type_p);
    if (type_p === "single") {
        $('#first-payment-checkbox').css('visibility', 'visible');
    } else {
        $('#first-payment-checkbox').css('visibility', 'hidden');
    }
}

let Utility = {
    menu: function() {
        let $hamburger = $('.hamburger-box');
        let $nav = $('nav.menu');
        $hamburger.on('click touch', function() {
            $hamburger.toggleClass('expanded');
            $nav.toggleClass('expanded');
        });
    },
    tabs: function() {
        $('#types input').on('touch click', check_strategy);

        $('#payments input').on('touch click', check_payment)
    },
    // formPreValidation: function() {
    //     let single_horizon = $('#single-horizon');
    //     single_horizon.on('input', function () {
    //         console.log(single_horizon.val())
    //         if ((single_horizon.val() - Math.floor( single_horizon.val() ) > 0)) {
    //             alert('nonono')
    //         }
    //     })
    // }

    // ,
    horizons: function() {
      $('#single-horizon').on('change', function () {
          $('#regular-horizon').val($('#single-horizon').val());
          console.log($('#single-horizon').val());
      })

        $('#regular-horizon').on('change', function () {
            $('#single-horizon').val($('#regular-horizon').val());
            console.log($('#single-horizon').val());
        })

    },
    submitButton: function () {
        $('#ivy-balanced').change(function() {
            $('#validation').removeClass('dynamic')
            $('#validation').addClass('balanced')

        });

        $('#ivy-dynamic').change(function() {
            $('#validation').addClass('dynamic')
            $('#validation').removeClass('balanced')
        });
    },
    init: function() {
        this.menu();
        this.tabs();
        this.submitButton();
        // this.formPreValidation();
        this.horizons();
    }
};
/**
 * Document ready
 */
$(document).ready(function(e) {
    Utility.init();
    check_strategy();
    check_payment();


});




$(function() {
    $('#validation').click(function(e) {
        $('#validation').addClass('loading')
        let valid = validation(e);
        if (!valid) {
            $('#validation').removeClass('loading');
            // return false;
        } else {
            // return true;
        }
    });
});

$('#single-horizon').change(function() {
    $('#regular-horizon').val($('#single-horizon').val());
});

$('#regular-horizon').change(function() {
    $('#single-horizon').val($('#regular-horizon').val());
});

function validation(e) {
    let valid = true;

    $(".form-group").removeClass("error");
    $(".errors-list").remove();

    let single = $('#type-combined').is(":checked") || $('#type-single').is(":checked");
    let regular = $('#type-combined').is(":checked") || $('#type-regular').is(":checked");

    let client = $('#client');
    if(client.val().trim() == "") {
        let base = client.parent();
        base.addClass("error");
        base.append($("<ul/>").addClass("errors-list").append($("<li/>").text("Jméno klienta nemůže být prázdné.")));
        valid = false;
    }

    let singleHorizon = $('#single-horizon');
    let sh = parseFloat(singleHorizon.val());

    if(single && (sh > 80 || sh < 5 || isNaN(sh))) {
        let base = singleHorizon.parent().parent();
        base.addClass("error");
        base.append($("<ul/>").addClass("errors-list").append($("<li/>").text("Investiční horizont musí být více než 5 let a max. 80 let")));
        valid = false;
    }
    if(single && sh - Math.floor(sh) > 0) {
        let base = singleHorizon.parent().parent();
        base.addClass("error");
        base.append($("<ul/>").addClass("errors-list").append($("<li/>").text("Investiční horizont musí být v celých letech")));
        valid = false;
    }

    let regularHorizon = $('#regular-horizon');
    let rh = parseFloat(regularHorizon.val());

    if(regular && (rh > 80 || rh < 5 || isNaN(rh))) {
        let base = regularHorizon.parent().parent();
        base.addClass("error");
        base.append($("<ul/>").addClass("errors-list").append($("<li/>").text("Investiční horizont musí být více než 5 let a max. 80 let")));
        valid = false;
    }

    if(regular && rh - Math.floor(rh) > 0) {
        let base = regularHorizon.parent().parent();
        base.addClass("error");
        base.append($("<ul/>").addClass("errors-list").append($("<li/>").text("Investiční horizont musí být v celých letech")));
        valid = false;
    }




    let singleInvestAmount = $("#single-amount");
    let singleInvestAmountValue = parseFloat(singleInvestAmount.val());
    console.log({singleInvestAmountValue})
    if(single && (singleInvestAmountValue <= 0 || isNaN(singleInvestAmountValue))) {
        let base = singleInvestAmount.parent().parent();
        base.addClass("error");
        base.append($("<ul/>").addClass("errors-list").append($("<li/>").text("Investovaná částka musí být větší než 0")));
        valid = false;
    }

    let regularInvestAmount = $("#regular-amount");
    let regulareInvestAmountValue = parseFloat(regularInvestAmount.val());
    console.log({regulareInvestAmountValue})
    if(single && (regulareInvestAmountValue <= 0 || isNaN(regulareInvestAmountValue))) {
        let base = regularInvestAmount.parent().parent();
        base.addClass("error");
        base.append($("<ul/>").addClass("errors-list").append($("<li/>").text("Investovaná částka musí být větší než 0")));
        valid = false;
    }


    let singleFee = $("#single-fee");
    let sf = parseFloat(singleFee.val());
    if(single && (sf > 5 || sf < 0 || isNaN(sf)))  {
        let base = singleFee.parent().parent();
        base.addClass("error");
        base.append($("<ul/>").addClass("errors-list").append($("<li/>").text("Poplatek u jednorázové investice může být max. 5 %")));
        valid = false;
    }

    let regularFee = $("#regular-fee");
    let rf = parseFloat(regularFee.val());
    if(regular && (rf > 5 || rf < 0 || isNaN(rf))) {
        let base = regularFee.parent().parent();
        base.addClass("error");
        base.append($("<ul/>").addClass("errors-list").append($("<li/>").text("Poplatek u pravidelné investice může být max. 5 %")));
        valid = false;
    }

    if(!valid) {
        let base = $("#error-text");
        base.append($("<ul/>").addClass("errors-list").append($("<li/>").text("Při odeslání se vyskytly chyby.")));
        base.addClass("error");
        e.preventDefault();
    }
    return valid
}